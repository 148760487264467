import { createFeature, createReducer, on } from '@ngrx/store';
import { DriverOnboardingActions } from './driver-onboarding.actions';

export const driverOnboardingFeatureKey = 'driverOnboarding';

export interface State {
  uploadingDriverPhoto: boolean;
  uploadingTaxiLicenseFrontPhoto: boolean;
  uploadingTaxiLicenseBackPhoto: boolean;
  savingOnboardingPersonalInfo: boolean;
  savingOnboardingDrivingInfo: boolean;
}

export const initialState: State = {
  uploadingDriverPhoto: false,
  uploadingTaxiLicenseFrontPhoto: false,
  uploadingTaxiLicenseBackPhoto: false,
  savingOnboardingPersonalInfo: false,
  savingOnboardingDrivingInfo: false
};

export const reducer = createReducer(
  initialState,
  on(DriverOnboardingActions.driverPhotoUploaded, (state): State => ({ ...state, uploadingDriverPhoto: true })),
  on(DriverOnboardingActions.driverPhotoUploadFinished, (state): State => ({ ...state, uploadingDriverPhoto: false })),
  on(DriverOnboardingActions.taxiLicenseFrontUploaded, (state): State => ({ ...state, uploadingTaxiLicenseFrontPhoto: true })),
  on(DriverOnboardingActions.taxiLicenseFrontUploadFinished, (state): State => ({ ...state, uploadingTaxiLicenseFrontPhoto: false })),
  on(DriverOnboardingActions.taxiLicenseBackUploaded, (state): State => ({ ...state, uploadingTaxiLicenseBackPhoto: true })),
  on(DriverOnboardingActions.taxiLicenseBackUploadFinished, (state): State => ({ ...state, uploadingTaxiLicenseBackPhoto: false })),
  on(DriverOnboardingActions.savingOnboardingPersonalInfoFinished, (state): State => ({ ...state, savingOnboardingPersonalInfo: false })),
  on(DriverOnboardingActions.savingOnboardingDrivingInfoFinished, (state): State => ({ ...state, savingOnboardingDrivingInfo: false })),
  on(DriverOnboardingActions.personalDetailsContinueClicked, (state): State => ({ ...state, savingOnboardingPersonalInfo: true })),
  on(DriverOnboardingActions.drivingInfoContinueClicked, (state): State => ({ ...state, savingOnboardingDrivingInfo: true })),
);

export const driverOnboardingFeature = createFeature({
  name: driverOnboardingFeatureKey,
  reducer,
});

