import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DriverOnboardingActions = createActionGroup({
  source: 'DriverOnboarding',
  events: {
    'Introduction video uploaded': emptyProps(),

    'Personal details continue clicked': props<{ firstName: string, lastName: string, phoneNumber: string }>(),

    'Driving info back clicked': emptyProps(),

    'Photo continue clicked': emptyProps(),

    'Photo back clicked': emptyProps(),

    'Driving info continue clicked': props<{ areaId: string, expiresAt: number, licenseNumber: string }>(),

    'Saving onboarding personal info finished': emptyProps(),

    'Saving onboarding driving info finished': emptyProps(),

    'Driver photo uploaded': props<{ imageSource: string }>(),

    'Driver photo upload finished': emptyProps(),

    'Taxi license front uploaded': props<{ imageSource: string }>(),

    'Taxi license front upload finished': emptyProps(),

    'Taxi license back uploaded': props<{ imageSource: string }>(),

    'Taxi license back upload finished': emptyProps(),

    'Onboarding edit profile clicked': emptyProps(),

    'Onboarding confirmation elearnigns url clicked': emptyProps(),

    'Logout button clicked': emptyProps()
  }
});
