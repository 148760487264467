@if (savingPersonalInfo$ | async) {
  <mat-progress-bar mode="indeterminate" class="progress-bar"></mat-progress-bar>
}
<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div class="container">
      <form [formGroup]="form" class="form">
        <div class="header">Fill in your personal details</div>
        <div class="name-group">
          <mat-form-field class="field" appearance="outline">
            <mat-label class="label">First name</mat-label>
            <input matInput formControlName="firstName">
            @if (hasError('firstName', 'required')) {
              <mat-error>
                First name is <strong>required</strong>
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field class="field" appearance="outline">
            <mat-label class="label">Last name</mat-label>
            <input matInput formControlName="lastName">
            @if (hasError('lastName', 'required')) {
              <mat-error>
                Last name is <strong>required</strong>
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="phone-group">
          <mat-form-field class="phone-number-field" appearance="outline">
            <mat-label class="label">Phone number</mat-label>
            <input matInput formControlName="phoneNumber">
            @if (hasError('phoneNumber', 'required')) {
              <mat-error>
                Phone number is <strong>required</strong>
              </mat-error>
            }
            @if (hasError('phoneNumber', 'pattern')) {
              <mat-error>
                Phone number is <strong>invalid</strong>. Number should start with country calling code, e.g. +45
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="buttons-container">
          <button color="accent" type="submit" mat-raised-button (click)="onContinueClick()"
          [disabled]="!form.valid || (savingPersonalInfo$ | async)">Continue</button>
        </div>
      </form>
    </div>
  </div>
</div>
