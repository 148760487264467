import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, switchMap, take, withLatestFrom } from 'rxjs';
import { AuthSelectors } from 'src/app/auth/store';
import { OnboardingConfirmationComponent } from './components/onboarding-confirmation/onboarding-confirmation.component';
import { OnboardingDrivingInfoComponent } from './components/onboarding-driving-info/onboarding-driving-info.component';
import { OnboardingElearningCooldownComponent } from './components/onboarding-elearning-cooldown/onboarding-elearning-cooldown.component';
import { OnboardingElearningComponent } from './components/onboarding-elearning/onboarding-elearning.component';
import { OnboardingIntroductionComponent } from './components/onboarding-introduction/onboarding-introduction.component';
import { OnboardingPersonalDetailsComponent } from './components/onboarding-personal-details/onboarding-personal-details.component';
import { OnboardingPhotoComponent } from './components/onboarding-photo/onboarding-photo.component';
import { OnboardingRejectionComponent } from './components/onboarding-rejection/onboarding-rejection.component';
import { DriverOnboardingSelectors } from './store';

export const driverOnboardingRouteGuard = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  function navigateTo(url: string) {
    if (url === state.url) {
      return [true];
    }
    router.navigateByUrl(url);
    return [false];
  }

  return store.select(AuthSelectors.selectDriverAggregate).pipe(
    filter(driverAggregate => driverAggregate !== undefined),
    withLatestFrom(store.select(DriverOnboardingSelectors.selectIsDriverOnboardingOnCooldown)),
    switchMap(([driverAggregate, isOnCooldown]) => {
      const onboarding = driverAggregate?.onboarding;
      if (driverAggregate?.driver) {
        return navigateTo('/home');
      }

      if (onboarding) {
        if (onboarding.rejected_at) {
          return navigateTo('/driver-onboarding/rejected');
        } else if (!onboarding?.introduction_video_url) {
          return navigateTo('/driver-onboarding/introduction');
        } else if (!onboarding?.first_name || !onboarding?.last_name || !onboarding.phone_number) {
          return navigateTo('/driver-onboarding/personaldetails');
        } else if (!onboarding?.area_id || !onboarding?.taxi_license_expires_at || !onboarding.taxi_license_number || !onboarding.taxi_license_back_photo_url || !onboarding.taxi_license_front_photo_url) {
          return navigateTo('/driver-onboarding/drivinginfo');
        } else if (!onboarding.profile_photo_url) {
          return navigateTo('/driver-onboarding/photo');
        } else if (!onboarding.exam_passed_at) {
          if (!isOnCooldown) {
            return navigateTo('/driver-onboarding/elearning');
          } else {
            return navigateTo('/driver-onboarding/elearning/cooldown');
          }
        } else {
          return navigateTo('/driver-onboarding/confirmation');
        }
      }

      return [false];
    }),
    take(1)
  )
}

const routes: Routes = [
  { path: '', redirectTo: 'introduction', pathMatch: 'full' },
  { path: 'introduction', component: OnboardingIntroductionComponent },
  { path: 'personaldetails', component: OnboardingPersonalDetailsComponent },
  { path: 'drivinginfo', component: OnboardingDrivingInfoComponent },
  { path: 'photo', component: OnboardingPhotoComponent },
  { path: 'confirmation', component: OnboardingConfirmationComponent },
  { path: 'elearning', component: OnboardingElearningComponent },
  { path: 'elearning/cooldown', component: OnboardingElearningCooldownComponent },
  { path: 'rejected', component: OnboardingRejectionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriverOnboardingRoutingModule { }
