
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule],
  standalone: true
})
export class ImageUploadComponent {
  @Input() isLoading: boolean | null = null;
  @Input() uploadText = '';
  @Input() imgUrl: string | null = null;
  @Output() fileUploaded = new EventEmitter<string>();

  onAddImage(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onloadend = () => {
        this.fileUploaded.next(fileReader.result as string);
      };
    }
  }
}
