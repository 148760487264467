@if (isLoading) {
  <div class="custom-file-upload">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
}

@if (!isLoading && !imgUrl) {
  <label class="custom-file-upload">
    <input accept="image/png, image/jpeg" type="file" (change)="onAddImage($event)">
    <i class="icon material-icons">upload_file</i>
    <div class="upload-text">
      {{ uploadText }}
    </div>
  </label>
}

@if (!isLoading && imgUrl) {
  <label>
    <input accept="image/png, image/jpeg" type="file" (change)="onAddImage($event)">
    <div class="uploaded-image-container">
      <i class="icon material-icons">upload_file</i>
      @if (imgUrl) {
        <img class="img" [src]="imgUrl">
      }
    </div>
  </label>
}
